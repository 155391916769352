import OpportunityCard from "@/components/opportunities/opportunityCard";
import GroupCard from "@/components/groups/groupCard";
import ArticleCard from "@/components/articles/articleCard";
import { FormattedMessage } from "react-intl";
import EmailVerifiedModal from "@/components/common/modals/emailVerifiedModal";
import IndustryExperienceModal from "@/components/common/modals/industryExperienceModal";
import { Link, usePage } from "@inertiajs/react";
import AppLayout from "../layouts/appLayout";
import RealmLeadBanner from "@/components/common/banners/RealmLeadBanner";
import AustraliaBanner from "@/components/common/banners/AustraliaBanner";
import { OpportunityView } from "@/types/opportunity";
import { GroupView } from "@/types/group";
import { ArticleView } from "@/types/article";
import { User } from "@/types/user";
import { Topic } from "@/types/topic";
import PollCard from "@/components/polls/pollCard";
import { Poll } from "@/types/poll";

const getCountry = (country: string) => {
    switch (country) {
        case "GB":
            return "the UK";
        case "CA":
            return "Canada";
        case "IT":
            return "Italia";
        default:
            return "the country";
    }
};

interface IndexInterface {
    opportunities: (OpportunityView & {
        timings: string;
        imageUrl: string;
        logoUrl: string;
        initials: string;
        closeAt: string;
        collaborator: {
            hasActiveLicense: boolean;
        };
    })[];
    opportunityCount: number;
    groups: (GroupView & {
        imageUrl: string;
        logoUrl: string;
        collaborator: { hasActiveLicense: boolean };
        isMember: boolean;
        members: (User & { image: string; initials: string })[];
    })[];
    groupCount: number;
    articles: (ArticleView & {
        heroUrl: string;
        user: { initials: string; image: string };
        publishedAt: string;
        topics: Topic[];
    })[];
    articleCount: number;
    polls: Poll[];
    pollCount: number;
    title: string;
    showEmailVerifiedSuccessModal: boolean;
    showIndustryExperiencePrompt: boolean;
    featuredOpportunities: (OpportunityView & {
        timings: string;
        imageUrl: string;
        logoUrl: string;
        initials: string;
        closeAt: string;
        collaborator: {
            hasActiveLicense: boolean;
        };
    })[];
}

export default function Index({
    opportunities,
    opportunityCount,
    groups,
    groupCount,
    articles,
    articleCount,
    title,
    showEmailVerifiedSuccessModal,
    showIndustryExperiencePrompt,
    featuredOpportunities,
    polls,
    pollCount,
}: IndexInterface) {
    const { realm, auth } = usePage().props;
    return (
        <>
            <AppLayout title={title}>
                {realm.country === "CA" && (
                    <RealmLeadBanner
                        backgroundImage="/images/realmLeads/McMasterBackground.png"
                        logoImage="/images/realmLeads/McMaster-logo-white.svg"
                        institution="McMaster University"
                        text="Voice Canada is supported by the McMaster Institute for Research on Aging (MIRA) as part of the global Voice network."
                        link="https://mira.mcmaster.ca/"
                        textClass="bg-mcmaster"
                    />
                )}
                {realm.country === "AU" && <AustraliaBanner />}
                <div className="max-w-7xl mx-auto px-6">
                    <div className="pt-6 sm:pt-10">
                        <h1 className="text-2xl sm:text-3xl font-medium sm:font-semibold">
                            <FormattedMessage id="Browse" />
                        </h1>
                        <p className="mt-1 font-light">
                            <FormattedMessage
                                id="The opportunities, groups and news, from across :country, updated daily"
                                values={{
                                    country: getCountry(realm.country),
                                }}
                            />
                        </p>
                    </div>
                    {featuredOpportunities.length > 0 && (
                        <>
                            <div className="flex items-center justify-between mt-9 border-t border-t-gray-300 pt-6">
                                <h2 className="text-xl font-semibold text-gray-900">
                                    <FormattedMessage id="Featured" />
                                </h2>
                            </div>
                            <div className="pt-4">
                                <ul
                                    role="list"
                                    className="grid grid-cols-1 gap-6 sm:grid-cols-2"
                                >
                                    {featuredOpportunities.map((item) => (
                                        <OpportunityCard
                                            key={item.id}
                                            opportunity={item}
                                        />
                                    ))}
                                </ul>
                            </div>
                        </>
                    )}
                    {opportunities.length > 0 && (
                        <>
                            <div className="flex items-center justify-between mt-9 border-t border-t-gray-300 pt-6">
                                <h2 className="text-xl font-semibold text-gray-900">
                                    <FormattedMessage id="Latest opportunities" />
                                </h2>
                                <p className="text-sm">
                                    <Link
                                        href={route("opportunities-index")}
                                        className="underline"
                                    >
                                        <FormattedMessage
                                            id="Show all :count"
                                            values={{ count: opportunityCount }}
                                        />
                                    </Link>
                                </p>
                            </div>
                            <div className="pt-4">
                                <ul
                                    role="list"
                                    className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
                                >
                                    {opportunities.map((item) => (
                                        <OpportunityCard
                                            key={item.id}
                                            opportunity={item}
                                        />
                                    ))}
                                </ul>
                            </div>
                        </>
                    )}
                    {auth?.user?.permissions?.includes("view poll") &&
                        polls.length > 0 && (
                            <>
                                <div className="flex items-center justify-between mt-9 border-t border-t-gray-300 pt-6">
                                    <h2 className="text-xl font-semibold text-gray-900">
                                        <FormattedMessage id="Polls" />
                                    </h2>
                                    <p className="text-sm">
                                        <Link
                                            href="/polls"
                                            className="border-b border-b-gray-900"
                                        >
                                            <FormattedMessage
                                                id="Show all :count"
                                                values={{ count: pollCount }}
                                            />
                                        </Link>
                                    </p>
                                </div>
                                <div className="pt-4">
                                    <ul
                                        role="list"
                                        className="flex flex-wrap gap-6"
                                    >
                                        {polls.map((poll) => (
                                            <PollCard
                                                key={poll.id}
                                                poll={poll}
                                            />
                                        ))}
                                    </ul>
                                </div>
                            </>
                        )}
                    {groups.length > 0 && (
                        <>
                            <div className="flex items-center justify-between mt-9 border-t border-t-gray-300 pt-6">
                                <h2 className="text-xl font-semibold text-gray-900">
                                    <FormattedMessage id="Groups" />
                                </h2>
                                <p className="text-sm">
                                    <Link
                                        href={route("groups-index")}
                                        className="border-b border-b-gray-900"
                                    >
                                        <FormattedMessage
                                            id="Show all :count"
                                            values={{ count: groupCount }}
                                        />
                                    </Link>
                                </p>
                            </div>
                            <div className="pt-4">
                                <ul
                                    role="list"
                                    className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
                                >
                                    {groups.map((item) => (
                                        <GroupCard key={item.id} group={item} />
                                    ))}
                                </ul>
                            </div>
                        </>
                    )}
                    {articles.length > 0 && (
                        <>
                            <div className="flex items-center justify-between mt-9 border-t border-t-gray-300 pt-6">
                                <h2 className="text-xl font-semibold text-gray-900">
                                    <FormattedMessage id="Latest news" />
                                </h2>
                                <p className="text-sm">
                                    <Link
                                        href={route("news-index")}
                                        className="underline"
                                    >
                                        <FormattedMessage
                                            id="Show all :count"
                                            values={{ count: articleCount }}
                                        />
                                    </Link>
                                </p>
                            </div>
                            <div className="pt-4 pb-16">
                                <ul
                                    role="list"
                                    className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
                                >
                                    {articles.map((item) => (
                                        <ArticleCard
                                            key={item.id}
                                            article={item}
                                        />
                                    ))}
                                </ul>
                            </div>
                        </>
                    )}
                </div>
            </AppLayout>
            {showEmailVerifiedSuccessModal && <EmailVerifiedModal />}
            {showIndustryExperiencePrompt && <IndustryExperienceModal />}
        </>
    );
}
